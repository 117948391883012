<template>
  <img
    :src="mainSrc"
    :alt="alt"
    :width="width"
    :height="height"
    :title="title"
    :loading="loading"
    :decoding="decoding"
    :sizes="sizes"
    :srcset="srcset"
    :usemap="usemap"
    :ismap="ismap"
    :crossorigin="crossorigin"
    :referrerpolicy="referrerpolicy"
    :fetchpriority="fetchpriority"
  />
</template>
<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  width: {
    type: [String, Number],
    default: 100,
  },
  height: {
    type: [String, Number],
    default: 100,
  },
  title: {
    type: String,
    default: '',
  },
  loading: {
    type: String as PropType<'eager' | 'lazy'>,
    default: 'eager',
  },
  fetchpriority: {
    type: String as PropType<'high' | 'low' | 'auto'>,
    default: 'auto',
  },
  decoding: {
    type: String as PropType<'sync' | 'async' | 'auto'>,
    default: 'auto',
  },
  sizes: {
    type: String,
    default: '',
  },
  srcset: {
    type: String,
    default: '',
  },
  usemap: {
    type: String,
    default: '',
  },
  ismap: {
    type: Boolean,
    default: false,
  },
  crossorigin: {
    type: String as PropType<'anonymous' | 'use-credentials'>,
    default: '',
  },
  referrerpolicy: {
    type: String as PropType<
      | ''
      | 'no-referrer'
      | 'no-referrer-when-downgrade'
      | 'origin'
      | 'origin-when-cross-origin'
      | 'same-origin'
      | 'strict-origin'
      | 'strict-origin-when-cross-origin'
      | 'unsafe-url'
    >,
    default: '',
  },
  format: {
    type: String as PropType<'avif' | 'webp' | 'jpeg'>,
    required: false,
    default: 'avif',
    validator: (value: string) => ['avif', 'webp', 'jpeg'].includes(value),
  },
  quality: {
    type: Number as unknown as PropType<number>,
    required: false,
    default: null,
  },
});
const image = useImage();

function generateFakeUrl() {
  const url = props.src;
  const params = new URLSearchParams();
  params.append('f', 'jpeg');
  params.append('s', '488x650');
  params.append('fit', 'cover');
  params.append('url', url);
  return `/_ipx/${params.toString()}`;
}

const mainSrc = computed(() => {
  const imgUrl = props.src
    ? image(props.src, {
        width: Number(props.width),
        height: Number(props.height),
        fit: 'cover',
        format: props.format,
        enlarge: true,
        ...(props.quality && { quality: props.quality }),
      })
    : generateFakeUrl();

  return imgUrl;
});

// watch(
//   mainSrc,
//   () => {
//     console.log('mainSrc', mainSrc.value);
//   },
//   {
//     immediate: true,
//   }
// );
</script>
